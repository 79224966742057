import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { InvestorEntityStatus, bankAccountApi, BankAccountProvisionState } from '@yieldstreet/platform-kit';
import { AppAction, appActionManager } from '../../../app-actions';
import { useInvestorEntity } from '../../../hooks';
export const getBankAccountAppAction = (investorEntityStatus, detailedBankAccounts, managementStrategy) => {
  const {
    id: investorEntityId,
    bankAccounts
  } = investorEntityStatus;
  const isDefaultBankDegraded = !!(detailedBankAccounts !== null && detailedBankAccounts !== void 0 && detailedBankAccounts.find(detailedBankAcct => detailedBankAcct.defaultAccount && detailedBankAcct.provisionState === BankAccountProvisionState.DEGRADED));
  if (isDefaultBankDegraded) {
    return appActionManager.getAction(AppAction.UPDATE_BANK_CONNECTION, {
      investorEntityId
    });
  }
  switch (bankAccounts.status) {
    case InvestorEntityStatus.MISSING:
      return appActionManager.getAction(AppAction.LINK_BANK_ACCOUNT, {
        investorEntityId,
        managementStrategy
      });
    case InvestorEntityStatus.PENDING:
      return appActionManager.getAction(AppAction.VERIFY_MICRO_DEPOSITS, {
        investorEntityId
      });
    default:
      return null;
  }
};
export const useBankAccountAppAction = (investorEntityId, managementStrategy) => {
  const {
    isLoading: isLoadingEntityData,
    investorEntityStatus
  } = useInvestorEntity(investorEntityId);
  const {
    isLoading: isLoadingBankData,
    data: bankData
  } = bankAccountApi.useGetEntityBankAccountsQuery(investorEntityId ? {
    investorEntityId
  } : skipToken);
  const detailedBankAccounts = bankData === null || bankData === void 0 ? void 0 : bankData.bankAccounts;
  const isLoading = isLoadingEntityData || isLoadingBankData;
  const bankAccountsAppAction = useMemo(() => investorEntityStatus && getBankAccountAppAction(investorEntityStatus, detailedBankAccounts, managementStrategy), [detailedBankAccounts, investorEntityStatus, managementStrategy]);
  return {
    isLoading,
    bankAccountsAppAction
  };
};
import { getAdviceOffers, getManagedPortfolioOffers, NON_ACCREDITED_OFFERS } from './offerings';
export const getPageConfig = function () {
  let isAdviceReady = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let investorEntityId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    nonAccredited: {
      offers: NON_ACCREDITED_OFFERS,
      description: 'For non-accredited investors, we have two exciting offerings available to you to start investing on Yieldstreet',
      callToAction: {
        type: 'button',
        buttonCTA: 'or fund your account first'
      },
      carousel: false
    },
    advice: {
      offers: getAdviceOffers(isAdviceReady),
      description: 'Discover the many ways we provide our new investors with the information they need to make their first investment.',
      carousel: false
    },
    managed: {
      offers: getManagedPortfolioOffers(investorEntityId),
      description: "Choose the path that's best for you below, whether you prefer to hand-pick your investment portfolio or let Yieldstreet do the work for you.",
      carousel: false
    }
  };
};
const getOfferType = (accreditedSignUp, showManagedPortfolio) => {
  if (!accreditedSignUp) return 'nonAccredited';
  return showManagedPortfolio ? 'managed' : 'advice';
};
export const useReadyToInvest = _ref => {
  let {
    accreditedSignUp,
    isAdviceReady,
    showManagedPortfolio,
    investorEntityId
  } = _ref;
  const offerType = getOfferType(accreditedSignUp, showManagedPortfolio);
  const pageConfig = getPageConfig(isAdviceReady, investorEntityId);
  return pageConfig[offerType];
};
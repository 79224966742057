import _Drawer from "@mui/material/Drawer";
import _Button from "@mui/material/Button";
import _Tooltip from "@mui/material/Tooltip";
import _Chip from "@mui/material/Chip";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toUsCurrency } from 'utils/currency-formatters';
import { PaymentStatusColor } from '../../TransactionsListData/TransactionsListData.model';
import { formattedDate, useTransactionDetails } from '../../hooks';
import { testIds } from './TransactionsListDesktopDrawer.model';
import { handlePaymentStatusLabel } from '../../TransactionsList.utils';
import { useManagementStrategy } from 'pages/portfolio/hooks/useManagementStrategy';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
export const TransactionsListDesktopDrawer = _ref => {
  let {
    drawerData,
    open,
    history,
    onClose
  } = _ref;
  const transactionsDetails = useTransactionDetails({
    transaction: drawerData
  });
  const {
    managementStrategy
  } = useManagementStrategy();
  const isManagedPortfolio = managementStrategy === PortfolioStrategyValues.Discretionary;
  const {
    anticipatedSettlementDate,
    destName,
    distribution,
    effectiveDate,
    fieldDate,
    investorBankAccountName,
    interest,
    isPayment,
    isDeposit,
    isFund,
    investmentLink,
    notes,
    principal,
    referenceNumber,
    status,
    showEffectiveDate,
    showSettlementDate,
    showNotes,
    sourceName,
    totalAmount
  } = transactionsDetails;
  return __jsx(_Drawer, _extends({
    anchor: "right",
    open: open,
    onClose: onClose
  }, testIds.base.attr), __jsx(_Stack, {
    sx: {
      padding: 6,
      height: '100%',
      width: '400px'
    },
    justifyContent: "space-between",
    gap: 4
  }, __jsx(_Stack, {
    gap: 6
  }, __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.totalAmount.attr), toUsCurrency(totalAmount)), __jsx(_Stack, {
    gap: 6,
    borderBottom: "1px solid",
    borderColor: "background.surface"
  }, __jsx(_Stack, testIds.sourceName.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "From"), __jsx(_Typography, {
    variant: "h6"
  }, sourceName)), __jsx(_Stack, _extends({
    sx: {
      mb: 6
    }
  }, testIds.destName.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "To"), __jsx(_Typography, {
    variant: "h6"
  }, destName))), __jsx(_Stack, testIds.date.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Date"), __jsx(_Typography, {
    variant: "body1"
  }, fieldDate)), __jsx(React.Fragment, null, showEffectiveDate ? __jsx(_Stack, testIds.effectiveDate.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Effective date"), __jsx(_Typography, {
    variant: "body1"
  }, effectiveDate ? formattedDate(effectiveDate) : '-')) : null, showSettlementDate ? __jsx(_Stack, testIds.expectedSettlementDate.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Expected settlement date"), __jsx(_Typography, {
    variant: "body1"
  }, anticipatedSettlementDate ? formattedDate(anticipatedSettlementDate) : '-')) : null), status ? __jsx(_Stack, testIds.status.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Status"), __jsx(_Chip, {
    label: handlePaymentStatusLabel(status),
    color: PaymentStatusColor[status],
    sx: {
      width: 'fit-content'
    }
  })) : null, (!isManagedPortfolio || isDeposit) && __jsx(React.Fragment, null, __jsx(_Stack, testIds.referenceNumber.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "ACH Trace ID", __jsx(_Tooltip, {
    title: "To track the status of this payment, provide the ACH trace ID to your bank."
  }, __jsx(InfoOutlinedIcon, {
    sx: {
      fontSize: 15,
      ml: 1
    }
  }))), __jsx(_Typography, {
    variant: "body1"
  }, referenceNumber || '-')), __jsx(_Stack, testIds.investorBankAccountName.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Bank account"), __jsx(_Typography, {
    variant: "body1"
  }, investorBankAccountName || '-'))), distribution ? __jsx(_Stack, testIds.distribution.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Distribution method"), __jsx(_Typography, {
    variant: "body1"
  }, distribution)) : null, showNotes && notes ? __jsx(_Stack, testIds.notes.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Payment note"), __jsx(_Typography, {
    variant: "body1"
  }, notes)) : null, isPayment && !isManagedPortfolio ? __jsx(React.Fragment, null, __jsx(_Stack, testIds.principal.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, isFund ? 'Capital' : 'Principal'), __jsx(_Typography, {
    variant: "body1"
  }, principal ? toUsCurrency(principal) : '-')), __jsx(_Stack, testIds.interest.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, isFund ? 'Income' : 'Interest'), __jsx(_Typography, {
    variant: "body1"
  }, interest ? toUsCurrency(interest) : '-'))) : null), investmentLink && __jsx(_Button, _extends({
    onClick: () => history.push(investmentLink),
    variant: "outlined"
  }, testIds.button.attr), "View investment")));
};
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { InvestorEntityStatusProvider, InvestorEntityType } from '@yieldstreet/platform-kit';
import { useFlags } from '../../flags';
/**
 * Legacy function used by the old hook. Should be removed when we clean up the feature flag.
 */
import { getInvestorEntityNextAction } from '../../hooks/use-investor-entity/useInvestorEntityNextAction';
import { AppAction, appActionManager } from '../../app-actions';
import { useAccreditationAppAction } from './utils/accreditationAppAction';
import { useBankAccountAppAction } from './utils/bankAccountAppAction';
import { useIdentityAppAction } from './utils/identityAppAction';
import { ActionCategory } from './useInvestorEntityAppActions.model';
export const useInvestorEntityAppActions = _ref => {
  let {
    action,
    investorEntity,
    investorEntityStatus,
    managementStrategy
  } = _ref;
  const {
    enableFrontendNextAction
  } = useFlags({
    enableFrontendNextAction: false
  });
  const {
    isLoading: isIdentityActionLoading,
    identityAppAction
  } = useIdentityAppAction((investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id) || '', managementStrategy);
  const {
    isLoading: isBankAccountsActionLoading,
    bankAccountsAppAction
  } = useBankAccountAppAction((investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id) || '', managementStrategy);
  const {
    isLoading: isAccreditationActionLoading,
    accreditationAppAction
  } = useAccreditationAppAction((investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id) || '');
  const isLoading = isAccreditationActionLoading || isBankAccountsActionLoading || isIdentityActionLoading;
  const nextAction = useMemo(() => {
    if (isLoading) {
      return null;
    }

    /**
     * We should show the Reactivate account CTA when KYC provider is still YS.
     */
    if ((investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.kycProvider) === InvestorEntityStatusProvider.YS) {
      /**
       * Legacy IRA, Traditional IRA and Roth IRA accounts are no longer able to invest nor migrate to Plaid.
       * Because of this, we should never return a next action CTA for them.
       */
      if (investorEntity && [InvestorEntityType.LEGACY_IRA, InvestorEntityType.TRADITIONAL_IRA, InvestorEntityType.ROTH_IRA].includes(investorEntity.type.name)) {
        return null;
      }
      return {
        appAction: appActionManager.getAction(AppAction.REACTIVATE_ACCOUNT, {}),
        actionCategory: ActionCategory.MIGRATION
      };
    }
    if (identityAppAction) {
      return {
        appAction: identityAppAction,
        actionCategory: ActionCategory.IDENTITY
      };
    }
    if (bankAccountsAppAction) {
      return {
        appAction: bankAccountsAppAction,
        actionCategory: ActionCategory.BANK
      };
    }
    if (accreditationAppAction) {
      return {
        appAction: accreditationAppAction,
        actionCategory: ActionCategory.ACCREDITATION
      };
    }
    return null;
  }, [isLoading, investorEntity, identityAppAction, bankAccountsAppAction, accreditationAppAction]);
  if (enableFrontendNextAction) {
    if (nextAction) {
      var _nextAction$appAction, _nextAction$appAction2, _nextAction$appAction3, _nextAction$appAction4;
      return _objectSpread(_objectSpread({}, nextAction.appAction), {}, {
        link: '',
        title: ((_nextAction$appAction = nextAction.appAction) === null || _nextAction$appAction === void 0 ? void 0 : (_nextAction$appAction2 = _nextAction$appAction.cta) === null || _nextAction$appAction2 === void 0 ? void 0 : _nextAction$appAction2.text) || '',
        action: (_nextAction$appAction3 = nextAction.appAction) === null || _nextAction$appAction3 === void 0 ? void 0 : (_nextAction$appAction4 = _nextAction$appAction3.cta) === null || _nextAction$appAction4 === void 0 ? void 0 : _nextAction$appAction4.callback,
        actionCategory: nextAction.actionCategory,
        isLoadingNextSteps: isLoading
      });
    }
    return null;
  }
  return getInvestorEntityNextAction(investorEntity, investorEntityStatus, action);
};
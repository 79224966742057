/* eslint-disable no-nested-ternary */
import { AccreditationType, InvestorEntityType, IdentityDocumentType, InvestorEntityActionCode, InvestorEntityStatusProvider, InvestorEntityStatus } from '@yieldstreet/platform-kit';
import { useMemo } from 'react';
import { isAccreditedInvestorEntityStepDone } from './useInvestorEntityStepDone';
import { getInvestorEntityType } from './useInvestorEntityType';
const isAccreditationRequired = _ref => {
  let {
    accreditation,
    accreditedSignUp
  } = _ref;
  return !isAccreditedInvestorEntityStepDone(accreditation, accreditedSignUp);
};
export const getAccreditationLink = (investorEntity, investorEntityStatus) => {
  var _investorEntity$aiqs;
  let accreditationType = '';
  let step = '';
  let incomeType = '';
  const aiq = (_investorEntity$aiqs = investorEntity.aiqs) === null || _investorEntity$aiqs === void 0 ? void 0 : _investorEntity$aiqs.find(aiq => !aiq.verified);
  const isAiqEditable = aiq && [InvestorEntityStatus.PENDING, InvestorEntityStatus.ACTION_NEEDED].includes(investorEntityStatus.accreditation.status);
  if (isAiqEditable) {
    switch (aiq.accreditationType) {
      case AccreditationType.THIRD_PARTY:
        accreditationType = 'thirdparty';
        step = 'info/';
        break;
      case AccreditationType.NET_WORTH:
        accreditationType = 'networth';
        step = 'info/';
        break;
      case AccreditationType.LICENSED_BROKER:
        accreditationType = 'license';
        break;
      case AccreditationType.INCOME:
      default:
        accreditationType = 'income';
        step = 'edit/';
        incomeType = 'select/';
        break;
    }
    return `/settings/accreditation/aiq/${accreditationType}/${investorEntity.id}/${step}${incomeType}${aiq.id}`;
  }
  const hasIncome = (aiq === null || aiq === void 0 ? void 0 : aiq.accreditationType) === AccreditationType.INCOME;
  const renewal = hasIncome && isAccreditationRequired(investorEntityStatus) ? `/income/${investorEntity.id}/renewal` : `/${investorEntity.id}`;
  return `/settings/accreditation/aiq${renewal}`;
};
export const getInvestorEntityAction = (action, investorEntity, investorEntityStatus, actionFunc) => {
  if (investorEntity && investorEntityStatus) {
    const investorEntityType = getInvestorEntityType(investorEntity);
    const {
      id,
      type
    } = investorEntity;
    const {
      entityType,
      identityDocumentType,
      iraAccountType
    } = type;
    const {
      identity
    } = investorEntityStatus;
    switch (action) {
      case InvestorEntityActionCode.SET_KYC_1:
        if ((investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.provider) === InvestorEntityStatusProvider.YS) {
          return null;
        }
        let link = '';
        switch (identity.provider) {
          case InvestorEntityStatusProvider.ETC:
            link = `/investor/kyc/${id}/provider/etc`;
            break;
          default:
            link = `/investor/kyc/${id}`;
        }
        return {
          title: 'Verify identity',
          required: true,
          screen: 'mole.kycFlow',
          link
        };
      case InvestorEntityActionCode.SET_KYC_2:
        if ((investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.provider) === InvestorEntityStatusProvider.YS) {
          return null;
        }
        return {
          title: 'Continue identity verification',
          required: true,
          screen: identity.status === InvestorEntityStatus.FAILED ? 'mole.FailedUpload' : 'mole.UploadInstructions',
          link: identity.provider !== InvestorEntityStatusProvider.YS ? `/investor/kyc/${id}` : entityType && [InvestorEntityType.LLC, InvestorEntityType.SOLE_PROPRIETOR, InvestorEntityType.PARTNERSHIP, InvestorEntityType.S_CORPORATION, InvestorEntityType.C_CORPORATION].includes(entityType) ? `/settings/upload-investor-documents/${id}/entity` : entityType === InvestorEntityType.TRUST && identityDocumentType === IdentityDocumentType.SSN ? `/settings/upload-investor-documents/${id}/trust-with-ssn` : iraAccountType ? `/settings/upload-investor-documents/${id}/ira` : `/settings/upload-investor-documents/${id}`
        };
      case InvestorEntityActionCode.INFORM_MAIN_BENEFICIAL_OWNER_DATA:
        return {
          title: 'Add beneficial owners',
          required: true,
          link: `/create-investor-account/${investorEntityType}/beneficial/${id}`
        };
      case InvestorEntityActionCode.UPLOAD_MAIN_BENEFICIAL_OWNERS_DOCUMENT:
        return {
          title: 'Upload beneficial owner documents',
          required: true,
          link: `/create-investor-account/${investorEntityType}/pending-documents/beneficial/${id}`
        };
      case InvestorEntityActionCode.INVITE_BENEFICIAL_OWNERS:
        return {
          title: 'Invite beneficial owners',
          required: true,
          link: `/create-investor-account/${investorEntityType}/pending-invites/beneficial/${id}`
        };
      case InvestorEntityActionCode.LINK_BANK_ACCOUNT:
        if ((investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.bankAccounts.provider) === InvestorEntityStatusProvider.YS) {
          return null;
        }
        return {
          title: 'Link bank account',
          required: true,
          screen: 'mole.BankMethodChoices',
          link: `/investor/manage/${id}/bank-accounts`,
          action: actionFunc
        };
      case InvestorEntityActionCode.VERIFY_ACCREDITATION:
        return {
          title: 'Submit accreditation',
          required: isAccreditationRequired(investorEntityStatus),
          screen: 'mole.AccreditationInfoContainer',
          link: getAccreditationLink(investorEntity, investorEntityStatus)
        };
      case InvestorEntityActionCode.EDIT_ACCREDITATION:
        return {
          title: 'Edit accreditation',
          required: isAccreditationRequired(investorEntityStatus),
          screen: 'mole.AccreditationInfoContainer',
          link: getAccreditationLink(investorEntity, investorEntityStatus)
        };
      case InvestorEntityActionCode.UPDATE_ACCREDITATION:
        return {
          title: 'Renew accreditation',
          required: isAccreditationRequired(investorEntityStatus),
          screen: 'mole.AccreditationInfoContainer',
          link: getAccreditationLink(investorEntity, investorEntityStatus)
        };
      case InvestorEntityActionCode.UPDATE_ACCREDITATION_DOCUMENTATION:
        return {
          title: 'Update accreditation documentation',
          required: isAccreditationRequired(investorEntityStatus),
          screen: 'mole.AccreditationInfoContainer',
          link: getAccreditationLink(investorEntity, investorEntityStatus)
        };
      case InvestorEntityActionCode.VERIFY_MICRO_DEPOSITS:
        return {
          title: 'Verify micro-deposits',
          required: true,
          screen: 'mole.BankAccountMicroDeposit',
          link: '/management/bank-accounts'
        };
      case InvestorEntityActionCode.VERIFY_BENEFICIARIES:
        return {
          title: 'Verify beneficiaries',
          required: true,
          link: `/create-investor-account/ira/${id}/beneficiary`
        };
      case InvestorEntityActionCode.SET_IRA_FEE:
        return {
          title: 'Review fees and pricing',
          required: true,
          link: `/create-investor-account/ira/${id}/pricing`
        };
      case InvestorEntityActionCode.SIGN_IRA_DOC:
        return {
          title: 'eSign your documents',
          required: true,
          link: `/sign-document/${id}`
        };
      case InvestorEntityActionCode.FUND_IRA:
        return {
          title: 'Fund your IRA',
          required: true,
          link: `/portfolio/transfer-funds`
        };
      case InvestorEntityActionCode.ITS_ON_US:
        return {
          title: `It's on us`,
          pending: true
        };
      default:
        return null;
    }
  }
  return null;
};
export const useInvestorEntityAction = (action, investorEntity, investorEntityStatus, actionFunc) => {
  return useMemo(() => getInvestorEntityAction(action, investorEntity, investorEntityStatus, actionFunc), [action, investorEntity, investorEntityStatus, actionFunc]);
};
import { AccreditationType, IdentityDocumentType, InvestorEntityProvider, InvestorEntityType, InvestorEntityTypeValue, IraAccountType, ManagementStrategy } from '../../enums';
export const investorEntityType = {
  entityType: null,
  identityDocumentType: IdentityDocumentType.SSN,
  iraAccountType: IraAccountType.TRAD,
  name: InvestorEntityType.INDIVIDUAL,
  value: InvestorEntityTypeValue.INDV
};
export const investorEntity = {
  id: 201924,
  userId: 495236,
  type: {
    name: InvestorEntityType.INDIVIDUAL,
    value: InvestorEntityTypeValue.INDV,
    entityType: null,
    identityDocumentType: IdentityDocumentType.SSN,
    iraAccountType: null
  },
  affiliationDetails: null,
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: 'US',
    addressComplete: false
  },
  signerTitle: null,
  name: 'Test Test',
  dateOfRecord: null,
  phoneNumber: null,
  active: true,
  hasMultipleOwners: false,
  employerName: null,
  wealthSource: null,
  retirementAccount: false,
  walletEnabled: true,
  // @ts-ignore
  advisoryRelationship: 'ADVISORY_ACK',
  provider: {
    type: InvestorEntityProvider.YS,
    data: null
  },
  investorAccounts: [{
    id: 201924,
    managementStrategy: ManagementStrategy.Self,
    walletEnabled: false,
    hasMultipleOwners: false,
    retirementAccount: false,
    complete: false,
    investorEntityId: '12345'
  }, {
    id: 307123,
    managementStrategy: ManagementStrategy.Discretionary,
    walletEnabled: false,
    hasMultipleOwners: false,
    retirementAccount: false,
    complete: false,
    investorEntityId: '23456'
  }],
  documentNumberProvided: false
};
export const investorEntityManagedAndSelf = {
  id: 349913,
  userId: 456,
  type: {
    name: InvestorEntityType.INDIVIDUAL,
    value: InvestorEntityTypeValue.INDV,
    entityType: null,
    identityDocumentType: IdentityDocumentType.SSN,
    iraAccountType: null
  },
  affiliationDetails: null,
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: 'US',
    addressComplete: false
  },
  signerTitle: null,
  name: 'Test Test',
  dateOfRecord: null,
  phoneNumber: null,
  active: true,
  hasMultipleOwners: false,
  employerName: null,
  wealthSource: null,
  retirementAccount: false,
  walletEnabled: true,
  // @ts-ignore
  advisoryRelationship: 'ADVISORY_ACK',
  provider: {
    type: InvestorEntityProvider.YS,
    data: null
  },
  investorAccounts: [{
    id: 1,
    managementStrategy: 'SELF',
    walletEnabled: false,
    hasMultipleOwners: false,
    complete: false,
    retirementAccount: false,
    investorEntityId: '1'
  }, {
    id: 2,
    managementStrategy: 'DISCRETIONARY',
    walletEnabled: false,
    hasMultipleOwners: false,
    complete: false,
    retirementAccount: false,
    investorEntityId: '2'
  }],
  documentNumberProvided: false
};
export const investorEntitySelfOnly = {
  id: 1,
  userId: 456,
  type: {
    name: InvestorEntityType.INDIVIDUAL,
    value: InvestorEntityTypeValue.INDV,
    entityType: null,
    identityDocumentType: IdentityDocumentType.SSN,
    iraAccountType: null
  },
  affiliationDetails: null,
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: 'US',
    addressComplete: false
  },
  signerTitle: null,
  name: 'Test Test',
  dateOfRecord: null,
  phoneNumber: null,
  active: true,
  hasMultipleOwners: false,
  employerName: null,
  wealthSource: null,
  retirementAccount: false,
  walletEnabled: true,
  // @ts-ignore
  advisoryRelationship: 'ADVISORY_ACK',
  provider: {
    type: InvestorEntityProvider.YS,
    data: null
  },
  investorAccounts: [{
    id: 1,
    managementStrategy: 'SELF',
    walletEnabled: false,
    hasMultipleOwners: false,
    complete: false,
    retirementAccount: false,
    investorEntityId: '394796'
  }],
  documentNumberProvided: false
};
export const investorEntities = {
  entities: [investorEntity],
  entitiesLimit: 5,
  entitiesLimitReached: false,
  individualEntityLimit: 2,
  individualEntityLimitReached: true,
  iraEntityLimit: 1,
  iraEntityLimitReached: false,
  success: true
};
export const multiInvestorEntities = {
  entities: [investorEntityManagedAndSelf, investorEntitySelfOnly],
  entitiesLimit: 5,
  entitiesLimitReached: false,
  individualEntityLimit: 2,
  individualEntityLimitReached: true,
  iraEntityLimit: 1,
  iraEntityLimitReached: false,
  success: true
};
export const investorEntityAiq = {
  aiqs: [{
    accreditationType: AccreditationType.INCOME,
    actionNeeded: false,
    active: true,
    createDate: 1082392343,
    documents: [],
    expired: false,
    id: 1234,
    investorEntityId: '201924',
    investorEntityName: 'Test Test',
    investorFirstName: 'Test',
    latestAcknowledgementDate: 1082392343,
    latestVerificationDate: 1082392343,
    userDisplayName: 'Test',
    userId: 123,
    verified: true
  }],
  success: true
};
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { investorEntityApi } from '@yieldstreet/platform-kit';
import { PageLoader, Paragraph } from '@yieldstreet/ui-kit';
import { useInvestorEntityAction, useFlags, useAccreditationAppAction } from '@yieldstreet/tool-kit';
import { useRouteInvestorEntityId, useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { RouteDetails } from '../../components/RouteDetails';
import { AccreditationCell } from './AccreditationCell';
import { accreditationActions, testIds } from './Accreditation.model';
import { AccreditationWrapper, AccreditationBlock, TextWrapper, ExpirationDate } from './Accreditation.style';
export const Accreditation = () => {
  const history = useHistory();
  const {
    enableFrontendNextAction
  } = useFlags({
    enableFrontendNextAction: false
  });
  const investorEntityId = useRouteInvestorEntityId();
  const {
    isLoading: isLoadingInvestorEntity,
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const pendingActions = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.allPendingActions;
  const legacyAccreditationActionLabel = intersection(accreditationActions, pendingActions)[0];
  const {
    accreditationAppAction
  } = useAccreditationAppAction((investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id) || '');

  /**
   * Legacy and will be revoved with the feature flag cleanup
   */
  const legacyAccreditationAction = useInvestorEntityAction(legacyAccreditationActionLabel, investorEntity, investorEntityStatus);
  const {
    isLoading: isLoadingAIQ,
    data: AIQ = []
  } = investorEntityApi.useGetInvestorEntityAiqQuery({
    investorEntityId
  });
  const accreditationAction = useMemo(() => {
    if (enableFrontendNextAction) {
      var _accreditationAppActi;
      return __jsx(AccreditationCell, {
        accreditationAppAction: accreditationAppAction,
        label: (accreditationAppAction === null || accreditationAppAction === void 0 ? void 0 : (_accreditationAppActi = accreditationAppAction.cta) === null || _accreditationAppActi === void 0 ? void 0 : _accreditationAppActi.text) || '',
        onClick: () => {
          var _accreditationAppActi2;
          return accreditationAppAction === null || accreditationAppAction === void 0 ? void 0 : (_accreditationAppActi2 = accreditationAppAction.cta) === null || _accreditationAppActi2 === void 0 ? void 0 : _accreditationAppActi2.callback();
        },
        testId: testIds.status.id
      });
    }
    return __jsx(AccreditationCell, {
      accreditationAppAction: legacyAccreditationAction,
      label: (legacyAccreditationAction === null || legacyAccreditationAction === void 0 ? void 0 : legacyAccreditationAction.title) || '',
      onClick: () => (legacyAccreditationAction === null || legacyAccreditationAction === void 0 ? void 0 : legacyAccreditationAction.link) && history.push(legacyAccreditationAction.link),
      testId: testIds.status.id
    });
  }, [enableFrontendNextAction, legacyAccreditationAction, accreditationAppAction, history]);
  const handleAccreditationLabel = useCallback(() => {
    if (AIQ[0].verified) {
      return __jsx(ExpirationDate, _extends({
        size: "small"
      }, testIds.expirationDate.attr), `Valid until ${dayjs(AIQ[0].expirationDate).format('MMM')}, ${dayjs(AIQ[0].expirationDate).format('YYYY')}`);
    }
    return __jsx(ExpirationDate, _extends({
      size: "small"
    }, testIds.expirationDate.attr), "Pending");
  }, [AIQ]);
  const isLoading = isLoadingInvestorEntity || isLoadingAIQ;
  if (isLoading) {
    return __jsx(PageLoader, {
      testId: testIds.pageLoader.id
    });
  }
  return __jsx(AccreditationWrapper, null, __jsx(RouteDetails, {
    headingTitle: "Accreditation"
  }, __jsx(AccreditationBlock, testIds.accreditationBlock.attr, __jsx(TextWrapper, null, __jsx(Paragraph, {
    semiBold: true
  }, "Accreditation status"), !isEmpty(AIQ) ? handleAccreditationLabel() : null), accreditationAction)));
};
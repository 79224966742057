var __jsx = React.createElement;
import React from 'react';
import { OfferingCardV2 } from '@yieldstreet/ui-kit';
import { getRoute } from '@yieldstreet/tool-kit';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { ADVICE_PREFERENCES_PATH } from 'pages/accountSettings/advice';
import { ADVICE_HOW_IT_WORKS_PATH } from 'pages/advice/adviceHowItWorks/AdviceHowItWorks.model';
import { AdviceOfferingCard } from '../components/AdviceOfferingCard/AdviceOfferingCard';
import { PRISM_FUND_OFFER_VERTICAL, REG_A_PLUS_OFFER } from './offeringsData';
import { getMarketplaceOfferingConfig } from 'pages/marketplace/Marketplace/OfferingList/OfferingListV2.utils';
import { CREATE_ACCOUNT_ROOT_PATH } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
import adviceChartMobile from '../components/images/advice-chart-mobile.png';
import adviceChart from '../components/images/advice-chart.png';
import offeringsMobile from '../components/images/advice-offerings-mobile.png';
import offerings from '../components/images/advice-offerings.png';
export const NON_ACCREDITED_OFFERS = [__jsx(OfferingCardV2, getMarketplaceOfferingConfig(REG_A_PLUS_OFFER, false)), __jsx(OfferingCardV2, getMarketplaceOfferingConfig(PRISM_FUND_OFFER_VERTICAL, false))];
const MARKETPLACE_OFFER = __jsx(AdviceOfferingCard, {
  title: "Select your own private market investments",
  description: "View all the available offerings sourced by our team of investment professionals. Select from a wide range of options, including private equity, venture capital, real estate, and more.",
  cardCTAText: "Browse available offerings",
  redirectPath: "/offerings",
  highlightImage: {
    desktop: offerings,
    mobile: offeringsMobile
  }
});
export const getAdviceOffers = isAdviceReady => [__jsx(AdviceOfferingCard, {
  tagText: "New",
  title: "Get private market investment recommendations",
  description: "Tap into the collective wisdom of our investment teams. Answer a few key questions to get a customizable set of investment recommendations in just 2-3 minutes.",
  cardCTAText: "Unlock recommendations",
  redirectPath: isAdviceReady ? ADVICE_PREFERENCES_PATH : ADVICE_HOW_IT_WORKS_PATH,
  highlightImage: {
    desktop: adviceChart,
    mobile: adviceChartMobile
  }
}), MARKETPLACE_OFFER];
export const getManagedPortfolioOffers = investorEntityId => [__jsx(AdviceOfferingCard, {
  tagText: "New",
  title: "Set up a Managed Portfolio",
  description: "Let Yieldstreet build and managed a portfolio based on your investment objectives. Access several funds from prominent asset managers.",
  cardCTAText: "Get started",
  redirectPath: getRoute(CREATE_ACCOUNT_ROOT_PATH, {
    accountType: 'individual',
    managementStrategy: PortfolioStrategyValues.Discretionary,
    investorEntityId,
    step: 'citizenship'
  }),
  highlightImage: {
    desktop: adviceChart,
    mobile: adviceChartMobile
  }
}), MARKETPLACE_OFFER];
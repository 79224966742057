import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["investorEntityId"],
  _excluded2 = ["investorEntityId"],
  _excluded3 = ["investorEntityId"],
  _excluded4 = ["provider"];
import { Tags } from '../common';
import { HttpMethods, ManagementStrategy } from '../../enums';
import { userApi } from '../user';
export const investorEntityApi = userApi.enhanceEndpoints({
  addTagTypes: [Tags.InvestorEntity, Tags.InvestorEntityDocuments, Tags.InvestorEntityStatus, Tags.UserContact, Tags.ManagedPortfolioEligibility, Tags.InvestorEntityProvider]
}).injectEndpoints({
  overrideExisting: true,
  endpoints: builder => {
    const getInvestorEntity = builder.query({
      query: params => ({
        url: `/a/api/investor-entity`,
        method: HttpMethods.Get,
        params
      }),
      providesTags: [Tags.InvestorEntity]
    });
    const createInvestorEntity = builder.mutation({
      query: data => ({
        url: `/a/api/investor-entity`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: [Tags.InvestorEntity, Tags.InvestorEntityStatus]
    });
    const updateInvestorEntity = builder.mutation({
      query: _ref => {
        let {
            investorEntityId
          } = _ref,
          data = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `/a/api/investor-entity/${investorEntityId}`,
          method: HttpMethods.Patch,
          data
        };
      },
      invalidatesTags: [Tags.InvestorEntity, Tags.InvestorEntityStatus, Tags.UserContact, Tags.ManagedPortfolioEligibility]
    });
    const getInvestorEntityAiq = builder.query({
      query: _ref2 => {
        let {
          investorEntityId
        } = _ref2;
        return {
          url: `/a/api/investor-entity/aiq/${investorEntityId}`,
          method: HttpMethods.Get
        };
      },
      transformResponse: response => response.aiqs || [],
      providesTags: [Tags.InvestorEntityDocuments]
    });
    const submitInvestorEntityAiq = builder.mutation({
      query: _ref3 => {
        let {
          investorEntityId,
          data
        } = _ref3;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/aiq`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.InvestorEntityStatus, Tags.InvestorEntity, Tags.InvestorEntityDocuments, Tags.ManagedPortfolioEligibility]
    });
    const createManagedAccount = builder.mutation({
      query: _ref4 => {
        let {
          investorEntityId
        } = _ref4;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/investor-account`,
          method: HttpMethods.Post,
          data: {
            managementStrategy: ManagementStrategy.Discretionary
          }
        };
      },
      invalidatesTags: [Tags.InvestorEntityStatus, Tags.InvestorEntity, Tags.ManagedPortfolioEligibility]
    });
    const getEtcProviderInvestorEntity = builder.query({
      query: _ref5 => {
        let {
            investorEntityId
          } = _ref5,
          data = _objectWithoutProperties(_ref5, _excluded2);
        return {
          url: `/a/api/investor-entity/${investorEntityId}/provider/etc`,
          method: HttpMethods.Get,
          data
        };
      },
      providesTags: [Tags.InvestorEntity, Tags.InvestorEntityProvider]
    });
    const getEtcProviderOccupationsList = builder.query({
      query: () => ({
        url: `/a/api/investor-entity/provider/etc/occupations`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.InvestorEntity, Tags.InvestorEntityProvider]
    });
    const updateEtcProviderInvestorEntity = builder.mutation({
      query: _ref6 => {
        let {
            investorEntityId
          } = _ref6,
          data = _objectWithoutProperties(_ref6, _excluded3);
        return {
          url: `/a/api/investor-entity/${investorEntityId}/provider/etc`,
          method: HttpMethods.Patch,
          data
        };
      },
      invalidatesTags: [Tags.InvestorEntity, Tags.InvestorEntityProvider]
    });
    const createProviderInvestorEntity = builder.mutation({
      query: _ref7 => {
        let {
            provider
          } = _ref7,
          data = _objectWithoutProperties(_ref7, _excluded4);
        return {
          url: `/a/api/investor-entity/provider/${provider}`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.InvestorEntity, Tags.ManagedPortfolioEligibility]
    });
    return {
      getInvestorEntity,
      createInvestorEntity,
      updateInvestorEntity,
      getInvestorEntityAiq,
      submitInvestorEntityAiq,
      createProviderInvestorEntity,
      createManagedAccount,
      getEtcProviderInvestorEntity,
      getEtcProviderOccupationsList,
      updateEtcProviderInvestorEntity
    };
  }
});
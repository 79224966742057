import { configureRTKMockStore, getMockEndpoint, investmentApi, investorEntityApi, investorEntityStatusApi, restApi, userApi, userState } from '@yieldstreet/platform-kit';
import { AppAction } from '../../app-actions';
const investorEntityId = '394911';
const eligibilitySubmitManagedPortfolioStrategyMock = {
  userGate: {
    gateCode: 'INVESTOR_ENTITY_GATE',
    gateText: 'Investor entity require some action to complete setup',
    actionable: true,
    attributes: null
  },
  investorData: null,
  investorEntities: [{
    investorEntityId: investorEntityId,
    name: 'Test User',
    gateData: {
      gateCode: 'MISSING_MANAGED_PORTFOLIO_STRATEGY',
      gateText: 'Entity is missing strategy, user action required',
      actionable: true,
      attributes: {
        action: {
          investorEntityId: null,
          code: 'LINK_BANK_ACCOUNT',
          status: null,
          nextStep: 'Link a Bank Account',
          priority: 100
        }
      }
    },
    ysIra: false,
    retirementAccount: false,
    provider: {
      type: 'ys',
      data: null
    },
    investorAccounts: [{
      investorAccountId: 395155,
      gateData: {
        gateCode: 'DEPOSIT_REQUIRED',
        gateText: 'Account does not have the initial deposit, user action required',
        actionable: true,
        attributes: null
      }
    }],
    advisoryRelationship: 'NO_ADVISORY'
  }]
};
const eligibilityTransferFundsMock = {
  userGate: {
    gateCode: 'INVESTOR_ENTITY_GATE',
    gateText: 'Investor entity require some action to complete setup',
    actionable: true,
    attributes: null
  },
  investorData: null,
  investorEntities: [{
    investorEntityId: investorEntityId,
    name: 'Test User',
    gateData: {
      gateCode: 'INVESTOR_ACCOUNT_GATE',
      gateText: 'All investor accounts for this entity have blocking gate',
      actionable: true,
      attributes: {
        action: null
      }
    },
    ysIra: false,
    retirementAccount: false,
    provider: {
      type: 'ys',
      data: null
    },
    investorAccounts: [{
      investorAccountId: 395155,
      gateData: {
        gateCode: 'DEPOSIT_REQUIRED',
        gateText: 'Account does not have the initial deposit, user action required',
        actionable: true,
        attributes: null
      }
    }],
    advisoryRelationship: 'NO_ADVISORY'
  }]
};
const investorEntitiesMock = {
  entitiesLimitReached: false,
  individualEntityLimitReached: false,
  iraEntityLimitReached: false,
  entitiesLimit: null,
  individualEntityLimit: null,
  iraEntityLimit: null,
  entities: [{
    id: investorEntityId,
    userId: 493294,
    type: {
      name: 'INDIVIDUAL',
      value: 'INDV',
      entityType: null,
      identityDocumentType: 'SSN',
      iraAccountType: null
    },
    address: {
      addressLine1: '300 Park Ave',
      addressLine2: '',
      city: 'New York',
      state: 'NY',
      postalCode: '10022-7402',
      countryCode: 'US',
      addressComplete: true
    },
    signerTitle: null,
    name: 'Test User',
    dateOfRecord: '2000-10-10',
    phoneNumber: '+19172222222',
    active: true,
    hasMultipleOwners: false,
    employerName: 'jnkh',
    employmentStatus: 'EMPLOYED',
    employmentIndustry: 'ATHLETICS_FITNESS',
    employmentTitle: 'ATTORNEY',
    employerAddress: {
      addressLine1: '300 North Los Angeles Street',
      addressLine2: '',
      city: 'Los Angeles',
      state: 'CA',
      postalCode: '90012',
      countryCode: 'US',
      addressComplete: true
    },
    wealthSource: null,
    retirementAccount: false,
    walletEnabled: true,
    advisoryRelationship: 'NO_ADVISORY',
    provider: {
      type: 'ys',
      data: null
    },
    investorAccounts: [{
      id: 395154,
      managementStrategy: 'SELF',
      walletEnabled: false,
      hasMultipleOwners: false,
      complete: false,
      retirementAccount: false
    }, {
      id: 395155,
      managementStrategy: 'DISCRETIONARY',
      walletEnabled: false,
      hasMultipleOwners: false,
      complete: false,
      retirementAccount: false
    }],
    documentNumberProvided: true,
    countryOfCitizenship: ['US'],
    residenceStatus: 'CITIZEN',
    countryOfTaxResidence: 'US',
    stateOfTaxResidence: 'NY',
    affiliationDetails: {
      isPoliticallyExposed: false,
      isFamilyPoliticallyExposed: false,
      isFinraAffiliated: false,
      controlCorporations: [],
      affiliatedExchange: []
    },
    createdDate: '2024-02-04T20:32:31Z',
    meta: null,
    aiqs: [],
    locked: true,
    firstName: 'Gustavo',
    lastName: 'Miyabe',
    kycProvider: 'PLAID'
  }],
  success: true
};
const allInvestorEntityStatusMock = {
  394911: {
    userId: 493294,
    investorEntityId: investorEntityId,
    investorEntityStatusId: 575,
    investorEntityType: 'INDIVIDUAL',
    readyToInvest: true,
    setupComplete: true,
    accreditedSignUp: true,
    provider: {
      status: 'CONFIRMED',
      statusDisplay: 'Confirmed',
      pendingActions: [],
      message: null,
      entityType: null,
      provider: null
    },
    identity: {
      status: 'CONFIRMED',
      statusDisplay: 'Confirmed',
      pendingActions: [],
      message: null,
      entityType: null,
      provider: 'PLAID'
    },
    accreditation: {
      status: 'MISSING',
      statusDisplay: 'Unverified',
      pendingActions: [],
      message: null,
      entityType: null,
      provider: 'YS'
    },
    bankAccounts: {
      status: 'MISSING',
      statusDisplay: 'None',
      pendingActions: [],
      message: null,
      entityType: null,
      provider: 'PLAID'
    },
    lastUpdatedAt: '2024-02-04T20:33:14Z',
    investorEntityAction: {
      investorEntityId: null,
      code: 'LINK_BANK_ACCOUNT',
      status: null,
      nextStep: 'Link a Bank Account',
      priority: 100
    },
    allPendingActions: ['LINK_BANK_ACCOUNT', 'VERIFY_ACCREDITATION'],
    id: investorEntityId,
    nextStep: 'Link a Bank Account',
    type: {
      name: 'INDIVIDUAL',
      value: 'INDV',
      entityType: null,
      identityDocumentType: 'SSN',
      iraAccountType: null
    },
    typeDescription: 'Individual'
  }
};
export const usePortfolioBasisMock = [getMockEndpoint(userApi, 'getUserState', userState), getMockEndpoint(investorEntityApi, 'getInvestorEntity', investorEntitiesMock), getMockEndpoint(investorEntityStatusApi, 'getAllInvestorEntityStatus', allInvestorEntityStatusMock)];
const getSubmitManagedPortfolioStrategyMock = () => configureRTKMockStore([...usePortfolioBasisMock, getMockEndpoint(investmentApi, 'managedPortfolioEligibility', {
  eligibility: eligibilitySubmitManagedPortfolioStrategyMock
})], {}, restApi.middleware);
const submitManagedPortfolioStrategyResponse = {
  isLoading: false,
  currentGate: AppAction.SUBMIT_MANAGED_PORTFOLIO_STRATEGY,
  action: undefined
};
const getTransferFundsMock = () => configureRTKMockStore([...usePortfolioBasisMock, getMockEndpoint(investmentApi, 'managedPortfolioEligibility', {
  eligibility: eligibilityTransferFundsMock
})], {}, restApi.middleware);
export const PortfolioEligibilityMockStores = {
  getSubmitManagedPortfolioStrategyMock,
  getTransferFundsMock
};
export const PortfolioEligibilityMockResults = {
  submitManagedPortfolioStrategyResponse
};
export const portfolioEligibilityCheckConfig = {
  investorEntityId,
  requiredActions: [],
  config: {
    skipPollChecks: true
  }
};
import _Typography from "@mui/material/Typography";
import _Stack from "@mui/material/Stack";
import _Alert from "@mui/material/Alert";
import _Button from "@mui/material/Button";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Link from "@mui/material/Link";
import _AlertTitle from "@mui/material/AlertTitle";
import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React, { useCallback, useMemo, useState } from 'react';
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { useHistory } from 'react-router-dom';
import { BankAccountProvider, InvestorEntityStatusProvider, InvestorEntityStatus } from '@yieldstreet/platform-kit';
import { useInvestorEntityFlags } from '@yieldstreet/tool-kit';
import { SurfaceType } from '@yieldstreet/ui-kit';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import AddIcon from '@mui/icons-material/Add';
import { KYCMigrationBankAccountsNotification } from 'pages/investorEntity/screens/KYC/migration';
import { PlaidBankAccountModal } from 'pages/accountSettings/NewBankAccount/plaidBankAccount/PlaidBankAccountModal';
import { useBankLinkingModal } from 'pages/bankLinking';
import { useDefaultBankModal } from 'sharedComponents/DefaultBank/hooks/useDefaultBankModal';
import { useRouteInvestorEntity, useRouteInvestorEntityId } from 'pages/investorEntity/utils';
import { useUserState } from 'utils/hooks';
import DepositVerificationModal from 'pages/accountSettings/TransferFunds/ExternalBanks/DepositVerificationModal';
import { DeleteBankAccountModal, EditBankNicknameModal, EmptyScreen, RouteDetails } from '../../components';
import { ExternalBankAccount } from './components/ExternalBankAccount';
import { useBankAccounts } from './hooks/useBankAccounts';
import { useBankAccountNotifications } from './hooks/useBankAccountNotifications';
import { testIds } from './BankAccounts.model';
import { FlexBankAccountWrapper, BankAccountsWrapper, Disclaimer } from './BankAccounts.style';
import { useGetEmptyMessage } from './hooks/useGetEmptyMessage/useGetEmptyMessage';
export const BankAccounts = () => {
  const {
    id: userId
  } = useUserState();
  const {
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const history = useHistory();
  const investorEntityId = useRouteInvestorEntityId();
  const {
    canChangeDefaultBank,
    canAddEntityBankAccounts,
    bankAccounts,
    isEntityBankAccountsLoading,
    refetchEntityBankAccounts
  } = useBankAccounts();
  const {
    isETCAccount,
    isYSIRA
  } = useInvestorEntityFlags(investorEntity);

  // the below can be removed once we stop using deposit verification modal
  const {
    0: showDepositVerificationModal,
    1: setShowDepositVerificationModal
  } = useState(false);
  const {
    0: showPlaidModal,
    1: setShowPlaidModal
  } = useState(false);
  const {
    0: selectedBankAccount,
    1: setSelectedBankAccount
  } = useState();
  const defaultBankModal = useDefaultBankModal({
    investorEntityId
  });
  const bankLinkingModal = useBankLinkingModal({
    investorEntityId,
    onSuccess: () => {
      refetchEntityBankAccounts();
    }
  });

  //FIXME this needs to pass through the plaid flow.
  const doShowDepositVerificationModal = useCallback(bankAccountId => {
    const bankAccount = find(bankAccounts, x => x.id === bankAccountId);
    setSelectedBankAccount(bankAccountId);
    if ((bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.provider) === BankAccountProvider.PLAID) {
      setShowPlaidModal(true);
    } else {
      setShowDepositVerificationModal(true);
    }
  }, [bankAccounts]);
  const hideDepositVerificationModal = () => {
    setShowDepositVerificationModal(false);
  };
  // end of the deposit modal specifics

  const canAddBankAccount = useMemo(() => {
    return !isETCAccount && canAddEntityBankAccounts && (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.status) === InvestorEntityStatus.CONFIRMED && (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.bankAccounts.provider) !== InvestorEntityStatusProvider.YS;
  }, [isETCAccount, canAddEntityBankAccounts, investorEntityStatus]);
  const canChangeDefaultBankAccount = useMemo(() => !isETCAccount && canChangeDefaultBank, [isETCAccount, canChangeDefaultBank]);
  const bankAccountNotifications = useBankAccountNotifications();
  const emptyMessage = useGetEmptyMessage();
  if (!showPlaidModal && (isEntityBankAccountsLoading || !userId)) {
    return __jsx(PageLoader, {
      testId: testIds.pageLoader.id
    });
  }
  if (isEmpty(bankAccounts)) {
    return __jsx(React.Fragment, null, __jsx(RouteDetails, {
      headingTitle: "Bank accounts"
    }), __jsx(_Box, {
      sx: {
        mt: 4
      }
    }, __jsx(KYCMigrationBankAccountsNotification, null)), __jsx(EmptyScreen, {
      heading: emptyMessage.heading,
      paragraph: emptyMessage.message,
      disabled: !canAddBankAccount,
      message: null,
      showAction: !isETCAccount && !isYSIRA,
      buttonText: "Add bank account",
      testId: testIds.addBankAccount.id,
      buttonAction: () => {
        bankLinkingModal.open();
      }
    }));
  }
  return __jsx(_Stack, {
    sx: {
      textAlign: 'left'
    }
  }, __jsx(DeleteBankAccountModal, null), __jsx(EditBankNicknameModal, null), __jsx(PlaidBankAccountModal, {
    bankAccountId: `${selectedBankAccount}`,
    investorEntityId: investorEntityId,
    onSuccess: () => {
      setShowPlaidModal(false);
      defaultBankModal.open();
    },
    onClose: () => {
      setShowPlaidModal(false);
    },
    showPlaidModal: showPlaidModal
  }), showDepositVerificationModal && __jsx(DepositVerificationModal, {
    hideModal: () => {
      hideDepositVerificationModal();
      refetchEntityBankAccounts();
    },
    bankAccountId: selectedBankAccount,
    history: history,
    userId: userId,
    redirectOnComplete: false
  }), __jsx(RouteDetails, {
    headingTitle: "Bank accounts"
  }), __jsx(_Stack, {
    sx: {
      mt: 4
    }
  }, bankAccountNotifications === null || bankAccountNotifications === void 0 ? void 0 : bankAccountNotifications.map(notification => {
    var _notification$callbac2;
    if (!(notification !== null && notification !== void 0 && notification.description)) {
      return null;
    }
    return __jsx(_Alert, {
      key: notification === null || notification === void 0 ? void 0 : notification.testId,
      "data-cy": notification === null || notification === void 0 ? void 0 : notification.testId,
      severity: notification === null || notification === void 0 ? void 0 : notification.severity
    }, (notification === null || notification === void 0 ? void 0 : notification.title) && __jsx(_AlertTitle, null, notification.title), __jsx(React.Fragment, null, notification === null || notification === void 0 ? void 0 : notification.description, (notification === null || notification === void 0 ? void 0 : notification.callback) && __jsx(React.Fragment, null, __jsx("br", null), __jsx(_Link, {
      "data-cy": "show-deposit-verification",
      onClick: () => {
        var _notification$callbac;
        return doShowDepositVerificationModal((notification === null || notification === void 0 ? void 0 : (_notification$callbac = notification.callback) === null || _notification$callbac === void 0 ? void 0 : _notification$callbac.action) && notification.callback.action());
      }
    }, notification === null || notification === void 0 ? void 0 : (_notification$callbac2 = notification.callback) === null || _notification$callbac2 === void 0 ? void 0 : _notification$callbac2.cta)), (notification === null || notification === void 0 ? void 0 : notification.updateBankButton) && __jsx(React.Fragment, null, __jsx("br", null), __jsx("br", null), __jsx(_Button, _extends({}, testIds.updateButton.attr, {
      variant: "contained",
      color: "warning",
      size: "small",
      onClick: () => {
        canChangeDefaultBankAccount ? defaultBankModal.open() : canAddBankAccount && bankLinkingModal.open();
      }
    }), "Update"))));
  })), __jsx(BankAccountsWrapper, {
    sx: {
      mt: 4
    },
    gap: 6
  }, bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.map(account => __jsx(FlexBankAccountWrapper, {
    type: SurfaceType.Highlight,
    key: account.id
  }, __jsx(ExternalBankAccount, {
    bankAccount: account
  }))), canAddBankAccount && __jsx(FlexBankAccountWrapper, {
    type: SurfaceType.Skeleton,
    withPadding: false
  }, __jsx(_Stack, _extends({
    sx: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      cursor: 'pointer',
      margin: 12
    },
    gap: 2,
    onClick: () => {
      bankLinkingModal.open();
    }
  }, testIds.addButton.attr), __jsx(AddIcon, {
    sx: {
      fontSize: '65px'
    }
  }), __jsx("p", null, "Add bank account")))), __jsx(Disclaimer, {
    sx: {
      mt: 8
    },
    gap: 4
  }, __jsx(_Typography, {
    variant: "body2"
  }, "You currently are limited to 3 external accounts per investor account."), canChangeDefaultBankAccount && __jsx(_Button, _extends({}, testIds.updateButtonPreferred.attr, {
    variant: "outlined",
    onClick: () => {
      defaultBankModal.open();
    }
  }), "Update preferred bank account")));
};
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { DistributionOption, DistributionOptionLabel, PortfolioPaymentTypes, PortfolioStrategyValues, PaymentStatus, PaymentMethodType } from '@yieldstreet/platform-kit';
import { PORTFOLIO_MD_PATH, PORTFOLIO_SD_PATH } from 'pages/portfolio/Portfolio.model';
import { useManagementStrategy } from 'pages/portfolio/hooks/useManagementStrategy';
export const formattedDate = date => dayjs(date).format('MMM D, YYYY');
export const useTransactionDetails = _ref => {
  let {
    transaction
  } = _ref;
  const {
    managementStrategy
  } = useManagementStrategy();
  const transactionsDetails = useMemo(() => {
    if (!transaction) {
      return {};
    }
    const {
      anticipatedSettlementDate,
      clearDate,
      date,
      destName,
      effectiveDate,
      fundDistributionType,
      investorBankAccountName,
      investorAccountId,
      isFund,
      interest,
      investmentMatured,
      notes,
      noteUrlHash,
      paymentMethod,
      principal,
      processDate,
      referenceNumber,
      status,
      sourceName,
      totalAmount,
      transferDate,
      transactionType,
      transactionTypeDisplayName
    } = transaction;
    const isClearedTransaction = status === PaymentStatus.Cleared;
    const isRefund = transactionType === PortfolioPaymentTypes.Refund;
    const isPayment = transactionType === PortfolioPaymentTypes.Payment;
    const isDeposit = transactionType === PortfolioPaymentTypes.Deposit;
    const isRollover = paymentMethod === PaymentMethodType.ROLL;
    const fieldDate = isClearedTransaction ? formattedDate(clearDate) : formattedDate(processDate) || formattedDate(transferDate) || formattedDate(date);
    const showEffectiveDate = isPayment || isRefund;
    const showSettlementDate = ['DEPOSIT', 'WITHDRAWAL'].includes(transactionType);
    const isShowingNotesForEqualization = transactionType === PortfolioPaymentTypes.Equalization && !!notes;
    const showNotes = isRefund || isPayment || isShowingNotesForEqualization;
    const distribution = fundDistributionType === DistributionOption.ReInvest ? DistributionOptionLabel[fundDistributionType] : null;
    const portfolioStrategyPath = managementStrategy === PortfolioStrategyValues.Discretionary ? PORTFOLIO_MD_PATH : PORTFOLIO_SD_PATH;
    const mainUrl = isClearedTransaction ? 'investment-details' : 'investment-details-pending';
    const baseUrl = investmentMatured ? 'investment-details-past' : mainUrl;
    const finalUrl = isFund ? `${baseUrl}-fund` : baseUrl;
    const investmentLink = noteUrlHash ? `/portfolio/${portfolioStrategyPath}/${finalUrl}/${noteUrlHash}/${investorAccountId}` : null;
    return {
      anticipatedSettlementDate,
      destName,
      distribution,
      effectiveDate,
      fieldDate,
      investorBankAccountName,
      interest,
      isRefund,
      isPayment,
      isDeposit,
      isRollover,
      isFund,
      investmentLink,
      notes,
      principal,
      referenceNumber,
      status,
      showEffectiveDate,
      showSettlementDate,
      showNotes,
      sourceName,
      totalAmount,
      transactionTypeDisplayName
    };
  }, [managementStrategy, transaction]);
  return transactionsDetails;
};
import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { NavigationTabList, MobileOnly } from '@yieldstreet/ui-kit';
import { InvestorEntityStatusProvider, InvestorEntityTypeName } from '@yieldstreet/platform-kit';
import { useDiscretionaryFlags } from 'hooks';
import { renderLink } from 'sharedComponents/Links/Links';
import { useRouteInvestorEntityId, useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { useUserState } from 'utils/hooks';
import { InformationBlock } from '../InformationBlock';
import { Wrapper, ContentWrapper } from './AccountLayout.style';
import { links, testIds } from './AccountLayout.model';
dayjs.extend(localizedFormat);
export const AccountLayoutMobile = () => {
  const investorEntityId = useRouteInvestorEntityId();
  const {
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const userState = useUserState();
  const {
    showManagedPortfolio
  } = useDiscretionaryFlags();
  const isEntityWalletVisible = useMemo(() => {
    var _investorEntityStatus;
    return (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus = investorEntityStatus.identity) === null || _investorEntityStatus === void 0 ? void 0 : _investorEntityStatus.provider) === InvestorEntityStatusProvider.YS;
  }, [investorEntityStatus]);
  return __jsx(MobileOnly, {
    sx: {
      width: '100%'
    }
  }, __jsx(Wrapper, null, __jsx(InformationBlock, null, __jsx(_Stack, {
    gap: 1
  }, __jsx(_Typography, _extends({
    variant: "h5"
  }, testIds.heading.attr), investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.name), __jsx(_Typography, _extends({
    variant: "body2"
  }, testIds.accountType.attr), (investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.name) && InvestorEntityTypeName[investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.name])), __jsx(_Box, {
    sx: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }, __jsx(_Box, null, __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, "Created"), __jsx(_Typography, _extends({
    variant: "body1"
  }, testIds.createdDate.attr), dayjs(investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.createdDate).format('ll')))))), __jsx(ContentWrapper, null, __jsx(NavigationTabList, {
    navigationConfig: links(investorEntityId, showManagedPortfolio, isEntityWalletVisible),
    renderLink: renderLink,
    userState: userState,
    vertical: true,
    textWrap: "nowrap"
  })));
};